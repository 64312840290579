import Chart, {
  CommonAxisSettings,
  CommonSeriesSettings,
  Format,
  Label,
  Series,
} from "devextreme-react/chart";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { getYearlyShipmentCount } from "../../../redux/widgets/action";

const YearlyShipmentCount = () => {
  const [barData, setBarData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [isLoading, setisLoading] = useState(true);

  const { yearlyShipmentCount } = useSelector((state) => state.WidgetsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId)
      dispatch(getYearlyShipmentCount(organizationId, moment().year()));
  }, [organizationId]);

  useEffect(() => {
    if (yearlyShipmentCount) {
      setBarData(yearlyShipmentCount);
      setisLoading(false);
    }
  }, [yearlyShipmentCount]);

  const customizePoint = useCallback(() => {
    return { color: "url(#Gradient2)", hoverStyle: { color: "#9E86FF" } };
  }, []);

  return (
    <div>
      {isLoading && <CircularProgress />}
      {barData?.length === 0 && isLoading === false && (
        <div className="noData">
          <div className="countryName">
            No data available to generate the Report
          </div>
        </div>
      )}
      {barData?.length > 0 && (
        <>
          <Chart
            id="chart"
            dataSource={barData}
            style={{ height: "250px" }}
            customizePoint={customizePoint}
          >
            <CommonAxisSettings grid={false} maxValueMargin="0.20" />
            <CommonSeriesSettings cornerRadius={12} />
            <Series
              valueField="count"
              argumentField="month"
              name="Bookings Created"
              type="bar"
              color="#826AF9"
              barWidth={25}
            >
              <Label visible={true} backgroundColor="#826AF9">
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
          </Chart>
          <>
            <svg className="svg-patterns">
              <defs>
                <linearGradient id="Gradient2" x1="1" x2="1" y1="1" y2="0">
                  <stop offset="5%" stopColor="#826AF9" />
                  <stop offset="110%" stopColor="#F7D2FF" />
                </linearGradient>
              </defs>
            </svg>
          </>
        </>
      )}
    </div>
  );
};

export default YearlyShipmentCount;
