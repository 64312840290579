import {
  Autocomplete,
  Box,
  Button,
  Chip,
  createFilterOptions,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FrachtButtonPanel from "../../commonControls/FrachtButtonPanel";
import FrachtCheckBox from "../../commonControls/FrachtCheckBox";
import {
  getEmailType,
  getOrganizationDetail,
  updateBreadcrumData,
} from "../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from "../../utils/axios";
import Loader from "../UI/Loader/Loader";
import Toast from "../UI/Toast/Toast";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FractAutoComplete from "../../commonControls/fractAutoComplete";
import FracthTextField from "../../commonControls/fracthTextField";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import ClearIcon from "@mui/icons-material/Clear";
import { bookingSettingStyle } from "../BookingSettings/style";

const EmailScenarioTable = () => {
  const { id } = useParams();
  const decodedOrgId = atob(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = bookingSettingStyle();
  const { emailtypes } = useSelector((state) => state.EditOrganization);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const generateUniqueId = () => {
    return `_${Math.random().toString(36).substr(2, 9)}`;
  };
  const EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const initialScenarios = [
    {
      id: generateUniqueId(),
      menuName: emailtypes[0]?.menuDisplayName,
      name: emailtypes[0]?.emailScenarioList[0]?.emailScenarioDisplayName || "",
      toEmail: "",
      ccEmail: "",
      enable: true,
      triggerMailOnlyToCcAddress: false,
    },
  ];
  const [fieldValues, setFieldValues] = useState({
    organizationId: "",
    scenarios: initialScenarios,
  });

  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [inputCCValue, setInputCCValue] = useState("");
  const [mailError, setMailError] = useState({});

  useEffect(() => {
    const breadCrumb = [
      {
        name: "Edit Organization",
        url: "/usermanagement/organization/editOrganization",
      },
      { name: "Email Settings" },
    ];
    dispatch(updateBreadcrumData(breadCrumb));
    return () => {
      dispatch(getOrganizationDetail(decodedOrgId));
      dispatch(updateBreadcrumData([]));
    };
  }, []);

  useEffect(() => {
    dispatch(getEmailType(organizationId));
    setDataLoading(true);
  }, [dispatch, organizationId]);

  useEffect(() => {
    const fetchData = async () => {
      // setDataLoading(true);
      try {
        // /api/1/organization/organizationid/{organizationId}/emailnotification
        const response1 = await API.get(
          `/1/organization/organizationid/${decodedOrgId}/emailnotification`
        );
        const standardColumns = response1?.data.value;
        const emailList = {
          organizationId: decodedOrgId || "",
          scenarios: standardColumns?.scenarios?.map((scenario) => {
            // Find corresponding email type based on menuName
            const emailType = emailtypes?.find(
              (type) => type.menuName === scenario.menuName
            );

            // Find corresponding email scenario based on name
            const emailScenario = emailType?.emailScenarioList?.find(
              (scenarioItem) => scenarioItem.emailScenarioType === scenario.name
            );

            return {
              id: generateUniqueId(),
              menuName:
                emailType?.menuDisplayName || emailtypes[0]?.menuDisplayName,
              name:
                emailScenario?.emailScenarioDisplayName ||
                emailtypes[0]?.emailScenarioList[0]?.emailScenarioDisplayName,
              toEmail: scenario.toEmail || "",
              ccEmail: scenario.ccEmail || "",
              enable: scenario.enable,
              triggerMailOnlyToCcAddress: scenario.triggerMailOnlyToCcAddress,
            };
          }) || [
            {
              id: generateUniqueId(),
              menuName: emailtypes[0]?.menuDisplayName,
              name: emailtypes[0]?.emailScenarioList[0]
                ?.emailScenarioDisplayName,
              toEmail: "",
              ccEmail: "",
              enable: true,
              triggerMailOnlyToCcAddress: false,
            },
          ],
        };
        setFieldValues(emailList);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setDataLoading(false);
      }
    };
    if (emailtypes?.length > 0) {
      // setDataLoading(false);
      fetchData();
    }
  }, [emailtypes, decodedOrgId]);

  const handleAddRow = () => {
    const newScenario = {
      id: generateUniqueId(),
      menuName: emailtypes[0]?.menuDisplayName || "",
      name: emailtypes[0]?.emailScenarioList[0]?.emailScenarioDisplayName || "",
      toEmail: "",
      ccEmail: "",
      enable: true,
      triggerMailOnlyToCcAddress: false,
    };

    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      scenarios: [newScenario, ...prevFieldValues.scenarios],
    }));
  };

  const deleteRow = (key) => {
    setFieldValues({
      ...fieldValues,
      scenarios: fieldValues?.scenarios?.filter((ele, i) => i !== key),
    });
  };

  const onInputChange = (e, newValue, index) => {
    const updatedInputValues = [...inputCCValue];
    updatedInputValues[index] = newValue;
    setInputCCValue(updatedInputValues);
  };

  const handleCheckboxChange = (index) => (event) => {
    const updatedScenarios = fieldValues.scenarios.map((scenario, i) =>
      i === index ? { ...scenario, enable: event.target.checked } : scenario
    );
    setFieldValues({ ...fieldValues, scenarios: updatedScenarios });
  };

  const handleRadioChange = (index, fieldName) => (event) => {
    const { value } = event.target;
    setFieldValues((prevFieldValues) => {
      const scenariosCopy = [...prevFieldValues.scenarios];
      scenariosCopy[index] = {
        ...scenariosCopy[index],
        triggerMailOnlyToCcAddress: value === "cc",
      };

      return {
        ...prevFieldValues,
        scenarios: scenariosCopy,
      };
    });
  };

  const toOptions = (emailtypes) => {
    return emailtypes.flatMap((parent) => [
      {
        id: parent.id,
        name: parent.menuDisplayName,
        parentId: null,
        isChild: false,
        matchTerms: [parent.menuDisplayName],
      },
      ...(parent.emailScenarioList || []).map((child) => ({
        id: `${parent.id}-${child.emailScenarioType}`,
        name: child.emailScenarioDisplayName,
        parentId: parent.id,
        isChild: true,
        matchTerms: [child.emailScenarioDisplayName, parent.menuDisplayName],
      })),
    ]);
  };

  const optionsList = toOptions(emailtypes);

  const getFullLabel = (option, allOptions) => {
    const parent = allOptions.find((opt) => opt.id === option.parentId);
    return parent ? `${parent.name} - ${option.name}` : option.name;
  };

  const handleInputChange = (event, index, fieldName) => {
    const { value } = event.target;

    setFieldValues((prevFieldValues) => {
      const scenariosCopy = [...prevFieldValues.scenarios];
      scenariosCopy[index] = {
        ...scenariosCopy[index],
        [fieldName]: value,
      };

      return {
        ...prevFieldValues,
        scenarios: scenariosCopy,
      };
    });
  };

  const emailChangeHandler = (newEmails, index) => {
    setFieldValues((prevFieldValues) => {
      const scenariosCopy = [...prevFieldValues.scenarios];
      scenariosCopy[index].ccEmail = newEmails;

      const updatedErrors = { ...mailError };
      // Remove error if the ccEmail field is being updated
      if (newEmails && updatedErrors[scenariosCopy[index].id]) {
        delete updatedErrors[scenariosCopy[index].id];
      }

      setMailError(updatedErrors);

      return {
        ...prevFieldValues,
        scenarios: scenariosCopy,
      };
    });
  };

  const onDelete = (emailToDelete, rowIndex) => {
    let existingEmails = fieldValues.scenarios[rowIndex].ccEmail;
    let emailArray = existingEmails.split(",");
    emailArray = emailArray.filter((email) => email !== emailToDelete);
    const updatedEmails = emailArray.join(",");
    emailChangeHandler(updatedEmails, rowIndex);
  };

  const isEmailValid = () => {
    const newErrors = {};

    fieldValues?.scenarios.forEach((scenario) => {
      if (
        scenario.triggerMailOnlyToCcAddress &&
        (!scenario.ccEmail || scenario.ccEmail.trim() === "")
      ) {
        newErrors[scenario.id] =
          "CC email is required when 'CC Only' is selected.";
        setToastMessage("CC email is required when 'CC Only' is selected.");
        setToastType(false);
      }
    });

    setMailError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // post api request
  const handleAddEmail = async () => {
    if (isEmailValid()) {
      const updatedScenarios = fieldValues.scenarios.map((scenario) => {
        const selectedEmailType = emailtypes?.find((type) =>
          type.emailScenarioList.some(
            (scenarioItem) =>
              scenarioItem.emailScenarioDisplayName === scenario.name
          )
        );

        const selectedScenario = selectedEmailType.emailScenarioList.find(
          (scenarioItem) =>
            scenarioItem.emailScenarioDisplayName === scenario.name
        );

        // Remove the id property
        const scenarioWithoutId = { ...scenario };
        delete scenarioWithoutId.id;

        return {
          ...scenarioWithoutId,
          menuName: selectedEmailType.menuName,
          name: selectedScenario.emailScenarioType,
        };
      });

      const adjustedFieldValues = {
        ...fieldValues,
        scenarios: updatedScenarios,
      };
      setDataLoading(true);
      try {
        const response = await API.put(
          "/1/organization/email",
          adjustedFieldValues
        );
        if (response.status === 200 || response.status === 201) {
          setToastMessage("Email added successfully.");
          setToastType(true);
          setTimeout(() => {
            dispatch(getOrganizationDetail(decodedOrgId));
            navigate("/usermanagement/organization/editOrganization");
          }, 1000);
        } else {
          setToastMessage(
            "Something went wrong at server. Please contact administrator"
          );
          setToastType(false);
        }
      } catch (error) {
        console.log("error", error);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setToastType(false);
      } finally {
        setDataLoading(false);
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
    dispatch(getOrganizationDetail(decodedOrgId));
  };

  return (
    <>
      {dataLoading && <Loader />}
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
      <Box sx={{ width: "100%", margin: "20px 0px" }} className="box-wrapper">
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: "auto", maxHeight: "calc(100vh - 270px)" }}
        >
          <Table aria-labelledby="tableTitle">
            <TableHead classes={{ root: classes.tableHead }}>
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: "2%" }}
                />
                <TableCell
                  className={classes.tableCell}
                  style={{ width: "30%" }}
                >
                  EMAIL TYPE <span style={{ color: "#CC3629" }}>*</span>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: "20%" }}
                  title="By selecting the option below, an email will be sent to both the TO and CC recipients."
                >
                  EMAIL (TO + CC){" "}
                  <InfoOutlinedIcon
                    style={{ marginBottom: "-5px", marginLeft: "5px" }}
                    fontSize="small"
                    id="emailInfoIcon"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: "40%" }}
                  title="After manually adding any email, press enter to convert it into a chip"
                >
                  CC EMAIL RECIPIENTS ONLY{" "}
                  <InfoOutlinedIcon
                    style={{ marginBottom: "-5px", marginLeft: "5px" }}
                    fontSize="small"
                    id="ccEmailInfoIcon"
                  />
                </TableCell>
                <TableCell className={classes.tableCell} width="8%">
                  <Button
                    startIcon={<AddSharpIcon />}
                    onClick={handleAddRow}
                    className={classes.emailRowAddButton}
                  >
                    Add Email
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody rowheight={70}>
              {fieldValues?.scenarios?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FrachtCheckBox
                      label=""
                      checked={data.enable}
                      changeHandler={handleCheckboxChange(index)}
                      dataTestId="enableCheckbox"
                      theme="dark"
                      margin="0px"
                    />
                  </TableCell>

                  <TableCell>
                    <FractAutoComplete
                      key={index}
                      id="type"
                      name="name"
                      label="Type"
                      options={optionsList || []}
                      getOptionLabel={(option) =>
                        option.isChild
                          ? getFullLabel(option, optionsList)
                          : option.name
                      }
                      getOptionDisabled={(option) => !option.isChild} // Disable parent options
                      onChange={(_, newValue) => {
                        if (newValue && newValue.isChild) {
                          handleInputChange(
                            { target: { value: newValue.name } },
                            index,
                            "name"
                          );
                        }
                      }}
                      selectedValue={
                        optionsList.find(
                          (option) =>
                            option.name ===
                              fieldValues.scenarios[index]?.name &&
                            option.isChild
                        ) || null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      filterOptions={(options, state) => {
                        const filtered = createFilterOptions({
                          stringify: (option) => option.matchTerms.join("//"),
                        })(options, state);

                        const mergedOptions = [];
                        const addedParentIds = new Set(); // Set to keep track of added parent option ids

                        filtered.forEach((option) => {
                          if (!option.isChild) {
                            // Add parent option to the list if it's not already added
                            if (!addedParentIds.has(option.id)) {
                              mergedOptions.push(option);
                              addedParentIds.add(option.id);
                            }
                          } else {
                            // Check if any child option matches the search
                            const childMatch = filtered.some(
                              (child) => child.id === option.id && child.isChild
                            );
                            if (childMatch) {
                              // If a child option matches, add its parent option to the list if not already added
                              const parent = options.find(
                                (parent) =>
                                  parent.id === option.parentId &&
                                  !parent.isChild
                              );
                              if (parent && !addedParentIds.has(parent.id)) {
                                mergedOptions.push(parent);
                                addedParentIds.add(parent.id);
                              }
                            }
                            // Always add child option to the list
                            mergedOptions.push(option);
                          }
                        });

                        return mergedOptions;
                      }}
                      required={true}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor: !option.isChild && "Gainsboro",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: option.isChild ? 18 : 0,
                              fontSize: option.isChild ? "14px" : "16px",
                              margin: "0px",
                              fontWeight: option.isChild ? "400" : "bold",
                            }}
                          >
                            {option?.name}
                          </div>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: "300px",
                          },
                        },
                      }}
                      ListboxProps={{
                        className: "myCustomList",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div className={classes.emailDiv}>
                      <RadioGroup
                        row
                        aria-label="email options"
                        name={`emailOptions-${index}`}
                        value={data.triggerMailOnlyToCcAddress ? "cc" : "to"}
                        onChange={handleRadioChange(
                          index,
                          "triggerMailOnlyToCcAddress"
                        )}
                      >
                        <FormControlLabel
                          value="to"
                          control={<Radio />}
                          label=""
                        />
                      </RadioGroup>
                      <FracthTextField
                        name="toEmail"
                        id="toEmail"
                        label="To Email"
                        value={data.triggerMailOnlyToCcAddress ? "" : "[User]"}
                        disabled={true}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.emailDiv}>
                      <RadioGroup
                        row
                        aria-label="email options"
                        name={`emailOptions-${index}`}
                        value={data.triggerMailOnlyToCcAddress ? "cc" : "to"}
                        onChange={handleRadioChange(
                          index,
                          "triggerMailOnlyToCcAddress"
                        )}
                      >
                        <FormControlLabel
                          value="cc"
                          control={<Radio />}
                          label=""
                        />
                      </RadioGroup>
                      <Autocomplete
                        className={classes.emailAutocomplete}
                        multiple
                        onChange={(e, value) => {
                          const lastIndex = value?.length - 1;
                          const newEmail = value[lastIndex];
                          if (lastIndex && newEmail) {
                            if (EMAIL_REGEX.test(newEmail)) {
                              const updatedEmails = value.join(",");
                              emailChangeHandler(updatedEmails, index);
                            } else {
                              const filteredValue = value.filter(
                                (email) => email !== newEmail
                              );
                              emailChangeHandler(
                                filteredValue?.join(","),
                                index
                              );
                            }
                          } else {
                            const updatedEmails = value?.join(",");
                            emailChangeHandler(updatedEmails, index);
                          }
                        }}
                        open={false}
                        id="emailField"
                        value={(fieldValues?.scenarios[index]?.ccEmail || "")
                          .split(",")
                          .filter((email) => email)}
                        inputValue={inputCCValue[index]}
                        onInputChange={(e, newValue) =>
                          onInputChange(e, newValue, index)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            const existingEmails =
                              fieldValues?.scenarios[index]?.ccEmail || "";
                            const newEmail = inputCCValue[index];

                            const updatedEmails = existingEmails
                              ? `${existingEmails},${newEmail}`
                              : newEmail;

                            emailChangeHandler(updatedEmails, index);

                            const updatedInputValues = [...inputCCValue];
                            updatedInputValues[index] = "";
                            setInputCCValue(updatedInputValues);
                          }
                        }}
                        // onBlur={(e) => handleEmailBlur(e, index)}
                        // onPaste={(e) => handlePaste(e, index)}
                        options={[]}
                        freeSolo
                        renderTags={(value, getTagProps) => {
                          // Ensure value is an array before mapping
                          if (Array.isArray(value)) {
                            return value.map((option, tagIndex) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ tagIndex })}
                                onDelete={() => onDelete(option, index)}
                                className={classes.emailTextField}
                              />
                            ));
                          }
                          return null;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Email Cc"
                            type="email"
                            className={classes.emailTextField}
                            error={!!mailError[data.id]}
                          />
                        )}
                      />
                    </div>
                  </TableCell>

                  <TableCell width="5%">
                    {fieldValues?.scenarios?.length > 1 && (
                      <IconButton
                        className={classes.clearBtn}
                        onClick={() => deleteRow(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <FrachtButtonPanel
        firstButtonText="Cancel"
        firstHandler={handleCancel}
        secondButtonText="Save"
        secondHandler={() => {
          handleAddEmail();
        }}
      />
    </>
  );
};

export default EmailScenarioTable;
