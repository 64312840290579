import { lazy } from "react";
import {
  BOOKEDROUTE,
  READYTOPICKUPROUTE,
} from "../constants/routesBasedOnStatus";
import POTabContainer from "../containers/FrankeOM/POTabContainer";
import DirectSummaryClient from "../components/MillerCoors/dataTable/directSummaryClient";
import RolesConfiguration from "../components/UserManagement/RolesConfiguration";
import ODQV2Widget from "../containers/Analytics/ODQV2Widget";
import ChildAppCard from "../components/UI/Card/ChildAppCard";
import EmailScenarioTable from "../components/EditOrganization/EmailScenarioTable";

const CreateShipment = lazy(() =>
  import("../containers/CreateShipment/CreateShipment")
);
const Dashboard = lazy(() => import("../containers/Dashboard/Dashboard"));
const SettingPage = lazy(() => import("../containers/SettingPage/SettingPage"));
const SummaryGrid = lazy(() => import("../containers/SummaryGrid/SummaryGrid"));
const ShipmentHistory = lazy(() =>
  import("../containers/ShipmentHistory/ShipmentHistory")
);
const BookingSettings = lazy(() =>
  import("../containers/BookingSettings/BookingSettings")
);
const Upload = lazy(() => import("../containers/Upload/Upload"));
const Analytics = lazy(() => import("../containers/Analytics/Analytics"));
const AdvanceBookingRequest = lazy(() =>
  import("../containers/AdvanceBookingRequest/AdvanceBookingRequest")
);
const EmptyContainerRequest = lazy(() =>
  import("../containers/EmptyContainerRequest/EmptyContainerRequest")
);
const MarketPlacePage = lazy(() => import("../errorPage/MarketPlace"));
const RestrictedPage = lazy(() => import("../errorPage/RestrictedPage"));
const ConstructionBG = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBG")
);
const BookingRequest = lazy(() =>
  import("../containers/BookingRequest/BookingRequest")
);
const AssignContainer = lazy(() =>
  import("../containers/AssignContainer/AssignContainer")
);
const EditDetailPage = lazy(() =>
  import("../containers/EditDetailPage/EditDetailPage")
);
const ServiceCenter = lazy(() =>
  import("../containers/ServiceCenter/ServiceCenter")
);
const NewScheduler = lazy(() =>
  import("../containers/NewScheduler/NewScheduler")
);
const ConstructionBG2 = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBG2")
);
const ConstructionBGAnalytics = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGAnalytics")
);
const ConstructionBGRates = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGRates")
);
const ConstructionBGMCS = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGMCS")
);
const ConstructionBGRCS = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGRCS")
);
const ConstructionBGISF = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGISF")
);
const ConstructionBGHistory = lazy(() =>
  import("../components/UI/ConstructionBG/ConstructionBGHistory")
);

// const OperationalDataQuality = lazy(() =>
//   import("../containers/Analytics/OperationalDataQuality")
// );
// const QuarterlyBusinessReview = lazy(() =>
//   import("../containers/Analytics/QuarterlyBusinessReview")
// );
// const Type1 = lazy(() => import("../containers/Analytics/Type1"));
// const Type2 = lazy(() => import("../containers/Analytics/Type2"));

// const ODQV1 = lazy(() => import("../containers/Analytics/ODQV1"));
// const ODQV2 = lazy(() => import("../containers/Analytics/ODQV2"));

// const ExecutiveDashboard = lazy(() =>
//   import("../containers/Analytics/ExecutiveDashboard")
// );
const UserManagement = lazy(() =>
  import("../containers/UserManagement/UserManagement")
);
const NewOrganization = lazy(() =>
  import("../containers/NewOrganization/NewOrganization")
);
const EditOrganization = lazy(() =>
  import("../containers/EditOrganization/EditOrganization")
);
const FindTruck = lazy(() => import("../containers/TruckLoad/findTruck"));
const AddNewUser = lazy(() => import("../containers/AddNewUser/AddNewUser"));
const EditUser = lazy(() => import("../containers/EditUser/EditUser"));
const TruckSummary = lazy(() => import("../containers/TruckLoad/truckSummary"));
const QuoteRequests = lazy(() =>
  import("../containers/TruckLoad/quoteRequests")
);
const Airlines = lazy(() => import("../containers/TruckLoad/airlines"));
const AirlinesSearchResult = lazy(() =>
  import("../components/AirlinesMenu/AirlinesSearchResult")
);
const OceanShipmentTracking = lazy(() =>
  import("../containers/shipmentTracking/oceanShipmentTracking")
);
const MillerCoorsAnalytics = lazy(() =>
  import("../containers/MillerCoors/MillerCoorsAnalytics")
);
const GridDemo = lazy(() => import("../containers/test/test"));
const MillerCoorsDashBoard = lazy(() =>
  import("../containers/MillerCoors/millerCorsDashboard")
);
const MCRSdataTable = lazy(() =>
  import("../containers/RailCarScheduler/MCRSdataTable")
);
const RatesDataTable = lazy(() => import("../components/Rates/RatesDataTable"));

const TrackAndTrace = lazy(() =>
  import("../containers/trackAndTrace/trackAndTrace")
);

// const KeyAccountManagement = lazy(() =>
//   import("../containers/Analytics/KeyAccountManagement")
// );
// const Volume = lazy(() => import("../containers/Analytics/Volume"));
// const CarrierPerformance = lazy(() =>
//   import("../containers/Analytics/CarrierPerformance")
// );
// const PerformanceManagement = lazy(() =>
//   import("../containers/Analytics/PerformanceManagement")
// );
// const CargowiseCertification = lazy(() =>
//   import("../containers/Analytics/CargowiseCertification")
// );

const ShipmentSummary = lazy(() =>
  import("../containers/Axis/shipmentSummary")
);
const MillerCoorsSetting = lazy(() =>
  import("../containers/MillerCoors/MillerCoorsSetting")
);
//const NalcoPOSummary = lazy(() => import("../containers/Axis/nalcoShipment"));
// const MolsonCoorsSummary = lazy(() =>
//   import("../containers/Axis/mcbcShipment")
// );
const WorkRequest = lazy(() =>
  import("../components/Notification/WorkRequest")
);
const ServiceHistoryTab = lazy(() =>
  import("../components/Notification/ServiceHistoryTab")
);

const TracckAndTraceSettings = lazy(() =>
  import("../containers/AppSettings/trackAndTraceSettings")
);
const AnalyticsSettings = lazy(() =>
  import("../containers/AppSettings/analyticsSettings")
);

const SummaryConfig = lazy(() =>
  import("../components/BookingSettings/SummaryConfig")
);
const AnalyticsReport = lazy(() =>
  import("../containers/Analytics/AnalyticsReport")
);

const ShipmentSummarySettings = lazy(() =>
  import("../containers/AppSettings/shipmentSummarySettings")
);

const privateRoutes = [
  { path: "/", exact: true, component: Dashboard, apiField: "dashboard" },
  {
    path: "/shipmentsummary/live",
    exact: true,
    component: ShipmentSummary,
    tabId: 0,
    name: "Live",
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/history",
    exact: true,
    component: ShipmentSummary,
    tabId: 1,
    name: "History",
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/:type",
    exact: true,
    component: ShipmentSummary,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/history/:type",
    exact: true,
    component: ShipmentSummary,
    tabId: 1,
    name: "History",
    apiField: "shipmentSummary",
  },
  {
    path: "/shipment/createShipment",
    exact: true,
    component: CreateShipment,
    apiField: "createShipment",
  },
  {
    path: "/shipment/history",
    exact: true,
    component: ShipmentHistory,
    tabId: 0,
    name: "ShipmentHistory",
    apiField: "history",
  },
  {
    path: "/shipment/draft",
    exact: true,
    component: ShipmentHistory,
    tabId: 1,
    name: "ShipmentDraft",
    apiField: "shipmentDraft",
  },
  {
    path: "/booking/configurationSettings/:id",
    exact: true,
    component: BookingSettings,
    tabId: 0,
    name: "MandatorySettings",
    apiField: "userManagement",
  },
  {
    path: "/booking/defaultSettings/:id",
    exact: true,
    component: BookingSettings,
    tabId: 1,
    name: "MandatorySettings",
    apiField: "userManagement",
  },
  {
    path: "/airlines/search",
    exact: true,
    component: Airlines,
    tabId: 0,
    name: "Airlines",
    apiField: "airlines",
  },
  {
    path: "/airlines/searchResult",
    exact: true,
    component: AirlinesSearchResult,
    name: "Airlines",
    apiField: "airlines",
  },
  {
    path: "/airlines/history",
    exact: true,
    component: Airlines,
    tabId: 1,
    name: "Airlines",
    apiField: "airlines",
  },
  {
    path: "/truckload/findtruck",
    exact: true,
    component: FindTruck,
    name: "Find a Truck",
    apiField: "trucking",
  },
  {
    path: "/truckload/summary",
    exact: true,
    component: TruckSummary,
    name: "Find a Truck",
    apiField: "trucking",
  },
  {
    path: "/truckload/openRequests",
    exact: true,
    component: QuoteRequests,
    tabId: 0,
    name: "QuoteRequests",
    apiField: "truckingQuoteRequests",
  },
  {
    path: "/truckload/history",
    exact: true,
    component: QuoteRequests,
    tabId: 1,
    name: "QuoteRequests",
    apiField: "truckingQuoteRequests",
  },
  {
    path: "/uploaddoc",
    exact: true,
    component: Upload,
    apiField: "documentUpload",
  },
  {
    path: "/restrictedPage",
    exact: true,
    component: RestrictedPage,
    apiField: "dashboard",
  },
  {
    path: "/marketPlace",
    exact: true,
    component: MarketPlacePage,
    apiField: "dashboard",
  },
  {
    path: "/shipment/analytics",
    exact: true,
    component: Analytics,
    apiField: "analytics",
  },

  {
    path: "/odqv2Widget",
    exact: true,
    component: ODQV2Widget,
    apiField: "dashboard",
  },
  { path: "/rates", exact: true, component: RatesDataTable, apiField: "rates" },
  {
    path: "/molsoncoors/advanceBookingRequest",
    exact: true,
    component: AdvanceBookingRequest,
    apiField: "millerCoors",
  },
  {
    path: "/emptyContainerRequest",
    exact: true,
    component: EmptyContainerRequest,
    apiField: "millerCoors",
  },
  {
    path: "/molsoncoors/dashboard",
    exact: true,
    component: MillerCoorsDashBoard,
    tabId: 0,
    name: "MillerCoors",
    apiField: "millerCoors",
  },
  {
    path: "/molsoncoors/dashboard1",
    exact: true,
    component: DirectSummaryClient,
    tabId: 0,
    name: "MillerCoors",
    apiField: "millerCoors",
  },
  {
    path: "/molsoncoors/dashboard/completedData",
    exact: true,
    component: MillerCoorsDashBoard,
    tabId: 1,
    name: "MillerCoors",
    apiField: "millerCoors",
  },
  {
    path: "/molsoncoors/analytics",
    exact: true,
    component: MillerCoorsAnalytics,
    apiField: "millerCoorsAnalytics",
  },
  {
    path: "/molsoncoors/settings",
    exact: true,
    component: MillerCoorsSetting,
    apiField: "millerCoorsSetting",
  },
  {
    path: "/molsoncoors/bookingRequest",
    exact: true,
    component: BookingRequest,
    apiField: "millerCoors",
  },
  {
    path: "/constructionBG/analytics",
    exact: true,
    component: ConstructionBGAnalytics,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG/history",
    exact: true,
    component: ConstructionBGHistory,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG",
    exact: true,
    component: ConstructionBG,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG2",
    exact: true,
    component: ConstructionBG2,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG/ISF",
    exact: true,
    component: ConstructionBGISF,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG/rates",
    exact: true,
    component: ConstructionBGRates,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG/MCS",
    exact: true,
    component: ConstructionBGMCS,
    apiField: "dashboard",
  },
  {
    path: "/constructionBG/RCS",
    exact: true,
    component: ConstructionBGRCS,
    apiField: "railCarSchedulerWorkInProgress",
  },
  {
    path: BOOKEDROUTE,
    exact: true,
    component: AssignContainer,
    apiField: "millerCoors",
  },
  {
    path: READYTOPICKUPROUTE,
    exact: true,
    component: EditDetailPage,
    apiField: "millerCoors",
  },
  {
    path: "/serviceCenter/ISFDashboard",
    exact: true,
    component: ServiceCenter,
    apiField: "serviceCenter",
  },
  {
    path: "/railCarScheduler",
    exact: true,
    component: MCRSdataTable,
    apiField: "railCarScheduler",
  },
  {
    path: "/railCarScheduler/newScheduler",
    exact: true,
    component: NewScheduler,
    apiField: "railCarScheduler",
  },
  {
    path: "/railCarScheduler/editScheduler",
    exact: true,
    component: NewScheduler,
    apiField: "railCarScheduler",
  },
  {
    path: "/usermanagement/organization",
    exact: true,
    component: UserManagement,
    tabId: 0,
    name: "Organization",
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/organization/oldGrid",
    exact: true,
    component: UserManagement,
    tabId: 0,
    name: "Organization",
    apiField: "userManagement",
  },

  {
    path: "/usermanagement/organization/newOrganization",
    exact: true,
    component: NewOrganization,
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/organization/editOrganization",
    exact: true,
    component: EditOrganization,
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/organization/editOrganization/oldGrid",
    exact: true,
    component: EditOrganization,
    apiField: "userManagement",
  },
  {
    path: "*",
    exact: true,
    component: Dashboard,
    apiField: "dashboard",
  },
  {
    path: "/usermanagement/users",
    exact: true,
    component: UserManagement,
    tabId: 1,
    name: "Users",
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/users/addnewuser",
    exact: true,
    component: AddNewUser,
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/users/edituser",
    exact: true,
    component: EditUser,
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/roles",
    exact: true,
    component: UserManagement,
    tabId: 2,
    name: "Users",
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/roles/addRole",
    exact: true,
    component: RolesConfiguration,
    apiField: "userManagement",
  },
  {
    path: "/usermanagement/roles/editRole",
    exact: true,
    component: RolesConfiguration,
    apiField: "userManagement",
  },
  {
    path: "/shipment/history/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "history",
  },
  {
    path: "/grid",
    exact: true,
    component: GridDemo,
  },
  {
    path: "/track/live",
    exact: true,
    component: TrackAndTrace,
    tabId: 0,
    name: "Live",
    apiField: "track",
  },
  {
    path: "/track/history",
    exact: true,
    component: TrackAndTrace,
    tabId: 1,
    name: "History",
    apiField: "track",
  },
  {
    path: "/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "track",
  },
  {
    path: "/track/live/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "track",
  },
  {
    path: "/track/history/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "track",
  },
  {
    path: "/shipmentsummary/live/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/history/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/origindelay/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/arrivaldelay/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/totalorders/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/delivered/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/loaded/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/sailed/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/deliverycompliance/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/shipmentsummary/live/dwelltime/tracking/:id",
    exact: true,
    component: OceanShipmentTracking,
    apiField: "shipmentSummary",
  },
  {
    path: "/poManagement",
    exact: true,
    component: POTabContainer,
    tabId: 0,
    name: "POTabContainer",
    apiField: "poManagement",
  },
  {
    path: "/poManagement/history",
    exact: true,
    component: POTabContainer,
    tabId: 1,
    name: "POTabContainer",
    apiField: "poManagement",
  },
  {
    path: "/workItems",
    exact: true,
    component: WorkRequest,
    apiField: "dashboard",
  },
  {
    path: "/serviceRequestHistory",
    exact: true,
    component: ServiceHistoryTab,
    apiField: "dashboard",
  },
  {
    path: "/TrackAndTrace/Settings/:id",
    exact: true,
    component: TracckAndTraceSettings,
    tabId: 0,
    name: "Track And Trace Settings",
    apiField: "userManagement",
  },
  {
    path: "/summaryGrid/Settings/:id",
    exact: true,
    component: SummaryConfig,
    apiField: "userManagement",
  },
  {
    path: "/email/Settings/:id",
    exact: true,
    component: EmailScenarioTable,
    apiField: "userManagement",
  },
  {
    path: "/analytics/Settings",
    exact: true,
    component: AnalyticsSettings,
    tabId: 0,
    name: "Analytics Settings",
    apiField: "userManagement",
  },
  {
    path: "/analytics/Settings/:type",
    exact: true,
    component: AnalyticsSettings,
    tabId: 0,
    name: "Analytics Settings",
    apiField: "userManagement",
  },
  {
    path: "/report/:id",
    exact: true,
    component: AnalyticsReport,
    apiField: "analytics",
  },
  {
    path: "/report/:id/:name",
    exact: true,
    component: AnalyticsReport,
    apiField: "analytics",
  },
  {
    path: "/analytics/card/:id",
    exact: true,
    component: ChildAppCard,
    apiField: "analytics",
  },
  {
    path: "/shipmentsummary/Settings/:id",
    exact: true,
    component: ShipmentSummarySettings,
    tabId: 0,
    name: "Shipment Summary Settings",
    apiField: "userManagement",
  },
  {
    path: "/summaryGrid",
    exact: true,
    component: SummaryGrid,
    apiField: "summaryGrid",
  },
  {
    path: "/settingPage/notificationSetting",
    exact: true,
    component: SettingPage,
    tabId: 0,
    name: "NotificationSetting",
    apiField: "dashboard",
  },
];

export default privateRoutes;
