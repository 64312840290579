import Chart, {
  CommonAxisSettings,
  CommonSeriesSettings,
  Format,
  Label,
  Series,
} from "devextreme-react/chart";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { getMontlyShipmentOverview } from "../../../redux/widgets/action";

const MontlyShipmentOverview = () => {
  const [barData, setBarData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);

  const {
    monthlyShipmentOverview,
    switchSelectionOverview,
    overviewCardLoading,
  } = useSelector((state) => state.WidgetsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId) {
      dispatch(getMontlyShipmentOverview(organizationId, moment().year()));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (monthlyShipmentOverview?.length > 0) {
      const selectedVolume =
        switchSelectionOverview === "ETD"
          ? monthlyShipmentOverview[0]?.shipmentMonthlyModels
          : monthlyShipmentOverview[1]?.shipmentMonthlyModels;
      setBarData(selectedVolume);
    } else if (monthlyShipmentOverview?.length === 0) {
      setBarData([]);
    }
  }, [monthlyShipmentOverview, switchSelectionOverview]);

  const customizePoint = useCallback((arg) => {
    return { color: "url(#Gradient2)", hoverStyle: { color: "#9E86FF" } };
  }, []);

  return (
    <div>
      {overviewCardLoading && <CircularProgress />}
      {!overviewCardLoading && barData?.length === 0 && (
        <div className="noData">
          <div className="countryName">
            No data available to generate the Report
          </div>
        </div>
      )}
      {!overviewCardLoading && barData?.length > 0 && (
        <>
          <Chart
            id="chart"
            dataSource={barData}
            style={{ height: "250px" }}
            customizePoint={customizePoint}
          >
            <CommonAxisSettings grid={false} maxValueMargin="0.20" />
            <CommonSeriesSettings cornerRadius={12} />
            <Series
              valueField="count"
              argumentField="month"
              name="Monthly shipment count"
              type="bar"
              color="#826AF9"
              barWidth={25}
            >
              <Label visible={true} backgroundColor="#826AF9">
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
          </Chart>
          <>
            <svg className="svg-patterns">
              <defs>
                <linearGradient id="Gradient2" x1="1" x2="1" y1="1" y2="0">
                  <stop offset="5%" stopColor="#826AF9" />
                  <stop offset="110%" stopColor="#F7D2FF" />
                </linearGradient>
              </defs>
            </svg>
          </>
        </>
      )}
    </div>
  );
};

export default MontlyShipmentOverview;
