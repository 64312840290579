export const SET_SETTING_LOADING = "SET_SETTING_LOADING";

export const GET_NOTIFICATION_SETTING_DATA = "GET_NOTIFICATION_SETTING_DATA";
export const GET_NOTIFICATION_SETTING_DATA_SUCCESS =
  "GET_NOTIFICATION_SETTING_DATA_SUCCESS";
export const GET_NOTIFICATION_SETTING_DATA_FAILURE =
  "GET_NOTIFICATION_SETTING_DATA_FAILURE";

export const RESET_NOTIFICATION_SETTING_DATA =
  "RESET_NOTIFICATION_SETTING_DATA";
