import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailIcon from "../../assets/images/MailIcon.png";
import DownloadIcon from "../../assets/images/DownloadIcon.png";
import Loader from "../UI/Loader/Loader";
import PdfViewer from "../../commonControls/PdfViewer";
import GridControl from "../../commonControls/devExGrid/gridControl";
import { DefaultGridConfiguration } from "../../commonControls/devExGrid/grid-settings";
import { Column } from "devextreme-react/data-grid";
import moment from "moment";
import NewRateTemplate from "../../../src/assets/images/NewRateTemplate.svg";
import get from "lodash/get";
import API from "../../utils/axios";
import { shipmentNotificationType } from "../../constants/enum";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: "16px 24px",
    backgroundColor: theme.palette.color.primary,
  },
  heading: {
    color: theme.palette.color.white,
  },
  disabledButton: {
    width: "100px",
    height: "38px",
    background: "#CED4DA80 0% 0% no-repeat padding-box",
    color: theme.palette.color.cyanBlueC1,
    font: "normal normal 600 14px/24px",
    letterSpacing: "0px",
    border: "0.5px solid #CFD4D9",
    borderRadius: "20px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      border: "1px solid #34CCFF1A",
      opacity: 1,
    },
  },
  clearIcon: {
    cursor: "pointer",
    color: theme.palette.color.white,
    width: "24px",
    height: "24px",
  },
  newIcon: {
    height: "20px !important",
    width: "20px !important",
    marginRight: "8px",
  },
  iconButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    margin: "0 16px 0 0",
    color: "#183650",
  },
  disableViewIcon: {
    width: "24px",
    height: "24px",
    cursor: "normal",
    margin: "0 16px 0 0",
    color: "#183650",
  },
}));

const allowedDocsView = [
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "raw",
  "bmp",
  "ico",
  "svg",
];

const DocumentDialogBoxNew = ({
  open,
  handleClose,
  message,
  docRowData,
  openEmailDialog,
  dataLoading,
  docDataLoading,
  showMail = true,
  shipmentId = "",
}) => {
  const classes = useStyles();
  const [selectedPDF, setSelectedPDF] = useState();
  const [gridData, setGridData] = useState([]);
  const childRef = React.useRef();
  const { organizationId } = useSelector((state) => state.LoginUser);

  const onClose = () => {
    try {
      if (Boolean(shipmentId)) {
        API.put(
          `/1/shipment-document/update-document-as-read?shipmentId=${shipmentId}`
        );
        const payload = {
          entityId: shipmentId,
          organizationId: organizationId,
          resourceName: "Shipment",
          notificationType: shipmentNotificationType.DOCUMENT,
        };

        API.put(
          `/1/notification/organizationid/notificationtype/entityid`,
          payload
        );
      }
    } catch (error) {}

    handleClose();
  };

  /**
   * Grid Event to capture column name on sorting
   */
  const onCellClick = (e) => {
    const { column, rowType } = e;
    if (rowType === "header" && column.allowSorting) {
      //   setSortColumn(
      //     column.dataField.charAt(0).toUpperCase() + column.dataField.slice(1)
      //   );
    }
  };

  const renderColumns = (head) => {
    switch (head.id) {
      default:
        return (
          <Column
            allowSorting={head.id !== "slno"}
            width={head.width}
            dataField={head.id}
            caption={head.label}
            dataType={head.type}
            cellRender={(cell) => {
              return (
                <Tooltip>
                  <span>{cell.data[head.id]}</span>
                </Tooltip>
              );
            }}
          />
        );
    }
  };

  useEffect(() => {
    let temp = docRowData.map((obj, index) => ({
      ...obj,
      slno: index + 1,
      submittedDate: new Date(obj.submittedDate),
    }));
    setGridData(temp);
  }, [docRowData]);

  const gridConfig = useMemo(() => {
    return {
      ...DefaultGridConfiguration,
      defaultColums: false,
      height: "auto",
      Id: "shipmentDocument-Table",
    };
  }, []);

  const downloadDocs = (row) => {
    try {
      let link = document.createElement("a");
      link.href = row.content;
      link.download = row.documentName;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      link.remove();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {}
  };

  return (
    <div>
      <PdfViewer
        openPdfViewer={selectedPDF?.content.length > 0}
        selectedPDF={selectedPDF}
        setSelectedPDF={setSelectedPDF}
      />
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 550,
          },
        }}
        maxWidth="md"
        // PaperProps={{ sx: { width: "564px", padding: "10px" } }}
      >
        {(dataLoading || docDataLoading) && <Loader />}
        <DialogTitle className={classes.dialogTitle} dividers>
          <Stack direction="row" justifyContent="space-between">
            <Typography className={classes.heading} variant="h3">
              Documents - {message}
            </Typography>
            <ClearIcon
              className={classes.clearIcon}
              onClick={onClose}
              id="ClearIcon"
            />
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <GridControl
            gridData={gridData}
            rowTemplate={null}
            onGridChange={() => {}}
            gridConfiguration={gridConfig}
            gridColums={[]}
            onCellClick={onCellClick}
            childRef={childRef}
            noDataText={
              docRowData.length === 0 ? "No document(s) available" : ""
            }
            onCellPrepared={(e) => {}}
          >
            {headCells.map((head) => {
              return renderColumns(head);
            })}

            <Column
              caption="Type of Document"
              width="30%"
              cellRender={(cellInfo) => {
                const row = cellInfo.data;
                return (
                  <Stack
                    className={classes.statusStack}
                    direction="row"
                    spacing={1}
                  >
                    {!row.isRead && (
                      <img
                        src={NewRateTemplate}
                        alt="No Images"
                        className={classes.newIcon}
                      />
                    )}
                    {row?.documentType}
                  </Stack>
                );
              }}
            ></Column>
            <Column
              caption="Name"
              width="30%"
              cellRender={(cellInfo) => {
                const row = cellInfo.data;
                const documentName = get(row, "documentName", "");
                const truncatedName =
                  documentName.length > 25
                    ? documentName.slice(0, 25) + "..."
                    : documentName;

                return <span title={documentName}>{truncatedName}</span>;
              }}
            />
            <Column
              caption="date"
              cellRender={(cellInfo) => {
                const row = cellInfo.data;
                return (
                  <span title={row?.uploadedDate}>
                    {row?.uploadedDate
                      ? moment(row?.uploadedDate).format(`MM/DD/YYYY`)
                      : ""}
                  </span>
                );
              }}
            />
            <Column
              caption="Action"
              cellRender={(cellInfo) => {
                const row = cellInfo.data;
                return (
                  <Box>
                    {allowedDocsView.includes(
                      row?.documentExtension?.toLowerCase()
                    ) ? (
                      <VisibilityIcon
                        id="VisibilityIcon"
                        className={classes.iconButton}
                        onClick={() => {
                          setSelectedPDF(row);
                        }}
                      />
                    ) : (
                      <Tooltip
                        title="File type not supported to view"
                        placement="right"
                        arrow
                      >
                        <VisibilityOffIcon
                          id="VisibilityOffIcon"
                          className={classes.disableViewIcon}
                        />
                      </Tooltip>
                    )}
                    {showMail && (
                      <img
                        src={MailIcon}
                        alt="MailIcon"
                        id="MailIcon"
                        className={classes.iconButton}
                        disabled={row.shipmentId === ""}
                        classes={{ disabled: classes.disabledButton }}
                        onClick={() => openEmailDialog(row, message)}
                      />
                    )}

                    <img
                      src={DownloadIcon}
                      alt="DownloadIcon"
                      id="DownloadIcon"
                      className={classes.iconButton}
                      disabled={row.shipmentId === ""}
                      classes={{ disabled: classes.disabledButton }}
                      onClick={() => downloadDocs(row)}
                    />
                  </Box>
                );
              }}
            ></Column>
          </GridControl>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentDialogBoxNew;

const headCells = [
  {
    id: "slno",
    label: "sl. No",
    width: "7%",
  },
];
